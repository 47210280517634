@import-normalize;

html {
  box-sizing: border-box;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
  font-size: 100%;
}

body {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

input {
  font-size: 100%;
}
