@import 'variables';

.appErrorBoundary {
  align-items: center;
  display: flex;
  flex-direction: column;

  .buttonContainer {
    padding-top: $large-gutter;
  }
}
