@import 'variables';

.shouldHighlightFocus {
  * {
    outline-width: 0;
    outline-color: $focus-color;
    outline-style: solid;
    outline-offset: 1px;
    transition: outline 150ms linear;

    &:focus {
      outline-width: 3px;
    }
  }

  a {
    &:focus {
      text-decoration: underline solid $focus-color;
    }
  }
}
