@import 'variables';

.breadCrumbs {
  align-items: center;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.breadCrumb {
  .slash {
    font-weight: bold;
  }
}
