@import 'variables';

.navigation {
  background-color: $blue;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: $nav-width;

  [data-reach-skip-link] {
    @include transition(opacity);
    left: 0;
    opacity: 0;
    padding: $small-gutter;
    position: fixed;
    top: 0;

    &:focus {
      opacity: 1;
    }
  }

  .logoContainer {
    padding: $extra-large-gutter $large-gutter $large-gutter;
    width: 100%;
  }

  .logo {
    align-items: center;
    display: flex;
    text-decoration: none;
    justify-content: center;
  }

  .logoImageContainer {
    border-radius: 50%;
    background-color: $white;
    padding: $extra-small-gutter;
  }

  .logoText {
    color: $white;
    font-size: $extra-large-font;
    padding-left: $small-gutter;
  }

  .currentEnvironment {
    align-items: center;
    color: $white;
    display: flex;
    flex-direction: column;
    padding-top: $small-gutter;
  }

  .currentEnvironmentLabel {
    font-size: $small-font;
  }

  .nav {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
  }

  .links {
    display: flex;
    flex-direction: column;
    padding: 0;
  }

  .link {
    align-items: center;
    color: $white;
    display: flex;
    font-size: $large-font;
    padding: $medium-gutter 0 $medium-gutter $medium-gutter;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .activeLink {
    background-color: $white;
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }

  .bottomLinks {
    align-items: center;
    border-top: $extra-small-gutter solid $white;
    display: flex;
    flex-direction: row;
    height: $extra-large-gutter;
    justify-content: space-between;
  }

  .bottomLink {
    align-items: center;
    background-color: $blue;
    color: $white;
    display: flex;
    flex: 1;
    font-size: $medium-font;
    height: 100%;
    justify-content: center;
    text-decoration: none;

    &:hover {
      background-color: $navy;
    }

    &:first-child {
      border-right: $extra-small-gutter solid $white;
    }
  }

  .bottomLinkActive,
  .bottomLinkActive:hover {
    background-color: $white;
    color: $black;
  }
}
