@import 'variables';

.confirmationOverlay {
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @include animation(fadein);
  align-items: center;
  background-color: transparentize($black, 0.5);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

.hideConfirmationOverlay {
  @keyframes fadeout {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @include animation(fadeout);
}

.confirmationContent {
  align-items: center;
  background-color: $pale-gray;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0;
  min-height: 175px;
  min-width: 300px;
  width: unset;
}

.confirmationLabel {
  font-weight: bold;
}

.confirmationDescription {
  padding: 0 $medium-gutter;
}

.confirmationActions {
  align-items: center;
  display: flex;
  justify-content: space-around;
  width: 100%;
}
