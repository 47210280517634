@import 'variables';

.flashMessages {
  @keyframes expand {
    from {
      height: 0;
    }
    to {
      height: $extra-large-gutter;
    }
  }

  @keyframes fadeincolor {
    from {
      color: transparent;
    }
    to {
      color: $white;
    }
  }

  @keyframes contract {
    from {
      height: $extra-large-gutter;
    }
    to {
      height: 0;
    }
  }

  @keyframes fadeoutcolor {
    from {
      color: $white;
    }
    to {
      color: transparent;
    }
  }

  color: $white;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;

  .flashMessage {
    @include animation(expand, fadeincolor);
    align-items: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
    width: 100%;
  }

  .contract {
    @include animation(contract, fadeoutcolor);
  }

  .success {
    background-color: $dark-green;
  }

  .notice {
    background-color: $dark-blue;
  }

  .error {
    background-color: $dark-red;
  }

  .closeButton {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-left: $small-gutter;
  }

  .closeIcon {
    @include animation(fadeincolor);
  }

  .closeIconContracting {
    @include animation(fadeoutcolor);
  }
}
