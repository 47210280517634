@import 'variables';

.page {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  padding: $large-gutter;
  position: relative;
}

.breadCrumbsContainer {
  left: $large-gutter;
  position: absolute;
  top: $small-gutter;
}
