@import 'variables';

.currentUserEmail {
  @keyframes fadein {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @include animation(fadein);
  color: $dark-gray;
  padding: $small-gutter $medium-gutter 0;
  position: fixed;
  left: 0;
  top: 0;
  text-align: center;
}
