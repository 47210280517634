@import 'variables';

.button {
  @include transition(all);
  background-color: $blue;
  border: none;
  border-radius: 3px;
  color: $white;
  cursor: pointer;
  font-size: $medium-font;
  padding: $medium-gutter $medium-gutter * 2;

  &:hover {
    background-color: $navy;
  }

  &:disabled {
    background-color: $medium-gray;
  }

  &.danger {
    background-color: $dark-red;
  }
}

.plainButton {
  background-color: transparent;
  border: none;
  border-radius: 3px;
  cursor: pointer;
  padding: 0;
  text-align: left;

  &.danger {
    svg {
      fill: $red;
    }

    &:hover {
      background-color: $red;

      svg {
        fill: $white;
      }
    }
  }
}
