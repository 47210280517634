@import 'variables';

.header {
  display: flex;
  justify-content: space-between;
  padding-bottom: $large-gutter;
  padding-top: $medium-gutter;
  min-height: 5em;
}

.title {
  font-size: $extra-large-font;
}

.headerActions {
  align-items: center;
  display: flex;

  > *:not(:last-child) {
    margin-right: $small-gutter;
  }
}
