@import 'variables';

.spinner {
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes colorshift {
    20% {
      border-color: $red;
    }
    40% {
      border-color: $gold;
    }
    60% {
      border-color: $green;
    }
    80% {
      border-color: $orange;
    }
    100% {
      border-color: $blue;
    }
  }

  animation: spin 1s infinite linear, colorshift 7.5s infinite linear;
  border-bottom: 2px solid $blue;
  border-left: 2px solid $blue;
  border-radius: 50%;
  min-height: $large-gutter;
  min-width: $large-gutter;
  position: relative;
}
