@import 'variables';

.appContainer {
  align-items: center;
  background-color: $white;
  color: $dark-gray;
  display: flex;
  flex-direction: row;
  height: 100vh;
  justify-content: space-between;
  width: 100vw;
}
