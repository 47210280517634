$default-font: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$header-font: 'Roboto Slab', 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', serif;

$hairline: 1px;
$extra-small-gutter: 3px;
$small-gutter: 6px;
$medium-gutter: 12px;
$large-gutter: 30px;
$extra-large-gutter: 45px;

$form-gutter-width: 200px;
$nav-width: 232px;
$page-max-width: 1280px;

$small-font: 0.75rem;
$medium-font: 1rem;
$large-font: 1.15rem;
$extra-large-font: 1.5rem;

$small-icon: 16px;
$medium-icon: 24px;
$large-icon: 32px;

$white: #fff;
$pale-gray: #f5f5f5;
$zircon: #e0e0e0;
$light-gray: #ccc;
$medium-gray: #707070;
$gray-black: #58595b;
$dark-gray: #222;
$black: #000;

$navy: #0039a7;

$blue: #1e6fd4;
$gold: #ffaa00;
$orange: #ff6900;
$green: #35a21c;
$red: #db2123;

$claim: $red;
$evidence: $gold;
$reasoning: $orange;
$counterargument: $green;
$audience: $blue;

$dark-blue: darken($blue, 10%);
$dark-gold: darken($gold, 10%);
$dark-orange: darken($orange, 10%);
$dark-green: darken($green, 10%);
$dark-red: darken($red, 10%);

$purple-tint: #e0cfeb;
$dark-green-tint: #a1d794;

$transition-duration: 300ms;

$focus-color: lightcoral;

@mixin animation($animation-names...) {
  $animations: '';

  @for $i from 1 through length($animation-names) {
    $animation-name: nth($animation-names, $i);
    $animation: '#{$animation-name} #{$transition-duration} ease forwards';

    @if $i == length($animation-names) {
      $animations: $animations + $animation;
    } @else {
      $animations: $animations + $animation + ',';
    }
  }

  animation: unquote($animations);
}

@mixin transition($attribute) {
  transition: $attribute $transition-duration ease;
}

@mixin truncate-text() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
